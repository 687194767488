export default function rippleTrigger(e, animationTime, button) {
	let x = e.clientX - e.target.offsetLeft + window.pageXOffset;
	let y = e.clientY - e.target.offsetTop + window.pageYOffset;
	let ripples = document.createElement('span');
	ripples.classList.add('ripple');
	ripples.style.background = 'rgb(194 65 12)';
	ripples.style.left = x + 'px';
	ripples.style.top = y + 'px';

	button.appendChild(ripples);
	document.querySelector('.ripple').style.setProperty('--animationTime', animationTime + 'ms');

	setTimeout(() => {
		button.removeChild(ripples);
		ripples.remove();
	}, animationTime);
}
