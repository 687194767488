import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import AerialRoad from '../images/min/aerial-road-autumn-min.jpg';
import FancyTruck from '../images/min/fancy-truck-min.jpeg';
import Arrow from '../svgs/Arrow';
import AOS from 'aos';
import Iveco from '../images/min/iveco1.jpg';

export default function Uns(props) {
  const setActivePage = props.setActivePage;
  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const switchAttributes = debounce(() => {
    const w = window.innerWidth;

    const card1 = document.getElementById('card1');
    const card2 = document.getElementById('card2');

    const card1Image = document.getElementById('card1image');
    const card2Image = document.getElementById('card2image');

    const card1Text = document.getElementById('card1text');
    const card2Text = document.getElementById('card2text');

    if (w > 1100) {
      //resetting all of the attributes for other elements as we want to animate the whole card
      card1Image.setAttribute('data-aos', '');
      card1Image.setAttribute('data-aos-delay', '');
      card1Text.setAttribute('data-aos', '');
      card2Image.setAttribute('data-aos', '');
      card2Image.setAttribute('data-aos-delay', '');
      card2Text.setAttribute('data-aos', '');
    }

    if (w > 1100) {
      card1.setAttribute('data-aos', 'fade-left');
      card2.setAttribute('data-aos', 'fade-right');
      card2.setAttribute('data-aos-delay', '800');
      card2.setAttribute('data-aos-delay', '400');
    } else {
      card1.setAttribute('data-aos', '');
      card2.setAttribute('data-aos', '');

      card1Image.setAttribute('data-aos', 'fade-left');
      card1Text.setAttribute('data-aos', 'fade-right');

      card2Image.setAttribute('data-aos', 'fade-left');
      card2Text.setAttribute('data-aos', 'fade-right');
    }
    setTimeout(() => {
      AOS.refreshHard();
    }, 150);
  }, 250);

  function initialAttributes() {
    const w = window.innerWidth;

    const card1 = document.getElementById('card1');
    const card2 = document.getElementById('card2');

    const card1Image = document.getElementById('card1image');
    const card2Image = document.getElementById('card2image');

    const card1Text = document.getElementById('card1text');
    const card2Text = document.getElementById('card2text');

    if (w > 1100) {
      card1.setAttribute('data-aos', 'fade-left');
      card2.setAttribute('data-aos', 'fade-right');
      card2.setAttribute('data-aos-delay', '800');
      card2.setAttribute('data-aos-delay', '400');
    } else {
      card1.setAttribute('data-aos', '');
      card2.setAttribute('data-aos', '');

      card1Image.setAttribute('data-aos', 'fade-left');
      card1Text.setAttribute('data-aos', 'fade-right');

      card2Image.setAttribute('data-aos', 'fade-left');
      card2Text.setAttribute('data-aos', 'fade-right');
    }

    setTimeout(() => {
      AOS.init({
        offset: 100,
        duration: 850,
      });
    }, 150); //gotta set some delay before initializing as without it everything that is animated disappears
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    setActivePage('uns');
    initialAttributes();
    window.addEventListener('resize', switchAttributes);
    return () => {
      window.removeEventListener('resize', switchAttributes);
    };
  }, []);

  return (
    <>
      <Header activePage={props.activePage} setActivePage={props.setActivePage} />

      <main className='h-auto site--content mb-[150px]'>
        <div className='w-[70%] mx-auto mb-16 uns--title res--change'>
          <h1 className='text-5xl font-semibold company--name--uns'>
            <span className='text-[#f97316]'>Fine</span>
            <span className='text-[#666666]'>log</span> GmbH
          </h1>
        </div>
        <div className='w-[70%] mx-auto cards--holder res--change'>
          <section className='contact--card' id='card1'>
            <div className='flex flex-col text--contact--card gap-4' id='card1text'>
              <p>
                {'Gr\u00FCndung'} <Arrow />
                <i>2015</i>
              </p>
              <p>
                {'Gesch\u00E4ftsf\u00FChrer'} <Arrow />
                <i>Dariusz Waku{'\u0142'}a</i>
              </p>
              <p>
                Mitarbeiter <Arrow />
                <i>115</i>
              </p>
              <p>
                Fahrer <Arrow />
                <i>89</i>
              </p>
              <p>
                Sitz <Arrow />
                <i>Porta Westfalica</i>
              </p>
            </div>
            <div className='card--image--holder' id='card1image'>
              <img loading='lazy' src={AerialRoad} className='card--image'></img>
            </div>
          </section>
          <section className='contact--card' id='card2'>
            <div className='flex flex-col text--contact--card gap-4' id='card2text'>
              <p>
                Zugmaschinen <Arrow />
                <i>40</i>
              </p>
              <p>
                Auflieger <Arrow />
                <i>45</i>
              </p>
              <p>
                PKW <Arrow />
                <i>6</i>
              </p>
              <p>
                Partnerschaft <Arrow />
                <i>Elvis</i>
              </p>
              <p>
                Kunden in der Portfolio <Arrow />
                <i>80</i>
              </p>
            </div>
            <div className='card--image--holder' id='card2image'>
              <img loading='lazy' src={FancyTruck} className='card--image'></img>
            </div>
          </section>
          <section className='contact--countries' data-aos='fade-down'>
            <div className='flex flex-col text--contact--card gap-4 w-3/4'>
              <h1 className='text-3xl title--uns--partners'>{'T\u00E4tigkeitsbereich'}</h1>
              <h2 className='text-md text-logo-gray mt-1 mb-4 w-[80%] text-center mx-auto desc--uns--partners'>
                Der nationale Verkehr in Deutschland steht im Vordergrund <br /> der T{'\u00E4'}
                tigkeit von Finelog.
              </h2>
              <div className='flex text--countries--contact justify-between w-full'>
                <div className='text-xl'>
                  <p>Deutschland</p>
                  <p>Frankreich</p>
                  <p>Italien</p>
                </div>
                <div className='h-full w-[2px] bg-special-gray'></div>
                <div className='text-xl'>
                  <p>{'\u00D6sterreich'}</p>
                  <p>die Schweiz</p>
                  <p>Polen</p>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className='w-[90%] mx-auto h-1 bg-logo-orange space--bar--uns rounded-full my-20'></div>
        <section>
          <div className='bg-gray-100'>
            <div className='w-[80%] mx-auto iveco--holder'>
              <div className='grid grid-cols-2 text-logo-gray py-10 gap-10 iveco--grid'>
                <div className='text-lg text-logo-gray flex flex-col justify-evenly site--content--text'>
                  <p data-aos='fade-down'>
                    Finelog ist ein Frachtf{'\u00FC'}hrer, spezialisiert sich im regelm{'\u00E4'}
                    {'\u00DF'}igen Linienverkehr und arbeitet au{'\u00DF'}schie{'\u00DF'}lich mit
                    und f{'\u00FC'}r Stammkunden in Deutschland.
                  </p>
                  <p data-aos='fade-down'>
                    Finelog opimiert ihre Flotte jedesmal f{'\u00FC'}r den Kunden und passt sich an
                    die Bed
                    {'\u00FC'}rfnisse den Kunden permanent an.
                  </p>
                  <p data-aos='fade-down'>
                    Finelog arbeitet sowohl f{'\u00FC'}r Unternehmen aus verschiedenen
                    Industriebereichen als auch f{'\u00FC'}r die Spediteure.
                  </p>
                </div>

                <img
                  src={Iveco}
                  className='rounded-[10px] object-cover iveco--image'
                  data-aos='fade-left'
                ></img>
              </div>
            </div>
          </div>
        </section>
        <div
          className='text-center text-lg text-logo-gray w-[70%] mx-auto mt-20 uns--bottom--text'
          data-aos='fade-down'
          data-offset='300'
        >
          <p className='text-logo-orange font-semibold text-xl mb-4 basic--info--title'>
            F{'\u00FC'}r uns sind die niedrigeren Kosten kein niedriger Preis sondern die Inteligenz
            der t{'\u00E4'}glichen T{'\u00E4'}tigkeit.
          </p>
          <div className='w-[90%] mx-auto site--content--text'>
            <p>
              Vor dem Start des Projektes werden alle Bed{'\u00FC'}rfnisse, M{'\u00F6'}glichkeiten
              und W{'\u00FC'}
              nsche des Kunden genau analysiert und optimalisiert.
            </p>
            <p>
              Dank dessen werden dem Kunden gezielte individuelle L{'\u00F6'}sungen vorgeschlagen,
              die sie meistens erwarten und mit denen sie zufrieden sind.
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
