import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import MainPage from './components/MainPage';
import './index.css';
import Header from './components/Header';
import Uns from './components/Uns';
import Kontakt from './components/Kontakt';
import Leistungen from './components/Leistungen';
import OkoFinelog from './components/OkoFinelog';
import Partnerschaft from './components/Partnerschaft';
import { useState, useEffect } from 'react';

export default function App() {
	const [activePage, setActivePage] = useState('start'); //determines which label on the header should be orange

	return (
		<>
			<Router>
				<Routes>
					<Route
						exact
						path="/"
						element={<MainPage activePage={activePage} setActivePage={setActivePage} />}></Route>
					<Route path="/uns" element={<Uns activePage={activePage} setActivePage={setActivePage} />}></Route>
					<Route
						path="/kontakt"
						element={<Kontakt activePage={activePage} setActivePage={setActivePage} />}></Route>
					<Route
						path="/leistungen"
						element={<Leistungen activePage={activePage} setActivePage={setActivePage} />}></Route>
					<Route
						path="/oko-finelog"
						element={<OkoFinelog activePage={activePage} setActivePage={setActivePage} />}></Route>
					<Route
						path="/partnerschaft"
						element={<Partnerschaft activePage={activePage} setActivePage={setActivePage} />}></Route>
				</Routes>
			</Router>
		</>
	);
}
