import React, { useEffect, useState, useRef } from 'react';
import Header from './Header';
import Footer from './Footer';
import City from '../images/min/city_shine_edited-min.jpeg';
import rippleTrigger from '../functions/rippleTrigger';
import axios from 'axios';
import validator from 'email-validator';
import AOS from 'aos';
import ReCAPTCHA from 'react-google-recaptcha';

export default function Kontakt(props) {
  const setActivePage = props.setActivePage;

  useEffect(() => {
    window.scrollTo(0, 0);
    const sendButton = document.querySelector('.button--send');
    sendButton.addEventListener('click', (e) => rippleTrigger(e, 1000, sendButton));
    setActivePage('kontakt');

    setTimeout(() => {
      AOS.init({
        offset: 100,
        duration: 850,
      });
    }, 150);
  }, []);

  const [sent, setSent] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    messageContent: '',
    messageSubject: '',
  }); //TODO ADD LAZY LOADING THROUGH THE loading="lazy" attribute to img

  //these variables hold current error text for each input
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [messageContentError, setMessageContentError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [phoneNumberError, setPhoneNumberError] = useState(null);
  const [messageSubjectError, setMessageSubjectError] = useState(null);
  const [goodSentText, setGoodSentText] = useState({ ok: false, text: '' });
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [recaptchaError, setRecaptchaError] = useState(null);

  //refs to each input
  const emailRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const phoneNumberRef = useRef();
  const messageContentRef = useRef();
  const messageSubjectRef = useRef();
  const emailSuccessRef = useRef();

  //refs to each error label to trigger .active class
  const emailErrorRef = useRef();
  const firstNameErrorRef = useRef();
  const lastNameErrorRef = useRef();
  const phoneNumberErrorRef = useRef();
  const messageContentErrorRef = useRef();
  const messageSubjectErrorRef = useRef();
  const recaptchaErrorRef = useRef();

  let emailValid;
  let firstNameValid;
  let lastNameValid;
  let phoneNumberValid;
  let messageContentValid;
  let messageSubjectValid;
  let recaptchaValid;

  function keyPressed(e) {
    if (e.key === 'Enter') {
      handleSend();
    }
  }

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  function recaptchaChange(value) {
    setRecaptchaValue(value);
  }

  function errorRecaptcha(error) {
    console.log(error);
  }

  function checkCharactersEmail() {
    const email = formData.email;
    const dotsInRow = /\.{2,}/g;
    const notAllowed = /[^a-z0-9\.\@]/g;
    const startsWithWrong = /^[^a-z0-9]/;
    const endsWithWrong = /[^a-z0-9]$/;

    if (email.match(startsWithWrong)) {
      setEmailError(
        'The first character of your email must be an ascii letter (a-z) or number (0-9)'
      );
      return false;
    }
    if (email.match(endsWithWrong)) {
      setEmailError(
        'The last character of your email must be an ascii letter (a-z) or number (0-9)'
      );
      return false;
    }
    if (email.match(notAllowed)) {
      setEmailError('Only letters (a-z), numbers (0-9), and periods (.) are allowed');
      return false;
    }
    if (email.match(dotsInRow)) {
      setEmailError('Your email cannot contain consecutive periods (.)');
      return false;
    }
    return true;
  }

  function checkCharactersPhone() {
    let valid = true;
    for (let i = 1; i < formData.phoneNumber.length; i++) {
      if (isNaN(formData.phoneNumber[i]) && formData.phoneNumber[i] !== '-') {
        setPhoneNumberError('This phone number format is not recognized');
        valid = false;
        break;
      }
    }
    return valid;
  }

  async function validateInputs() {
    emailValid = false;
    firstNameValid = false;
    lastNameValid = false;
    messageSubjectValid = false;
    messageContentValid = false;
    phoneNumberValid = false;
    recaptchaValid = false;

    if (!formData.firstName && !formData.lastName) {
      setFirstNameError('Enter your first name');
      setLastNameError('Enter your last name');
    } else if (!formData.firstName) {
      setFirstNameError('Enter your first name');
      setLastNameError(null);
      lastNameValid = true;
    } else if (!formData.lastName) {
      setLastNameError('Enter your last name');
      setFirstNameError(null);
      firstNameValid = true;
    } else {
      setFirstNameError(null);
      setLastNameError(null);
      lastNameValid = true;
      firstNameValid = true;
    }

    if (!formData.email) {
      setEmailError('Choose an email address');
    } else if (formData.email.indexOf('@') === -1) {
      setEmailError("Don't forget to include the '@'");
    } else if (formData.email.indexOf('@') === formData.email.length - 1) {
      setEmailError("Enter a domain name after the '@'");
    } else if (!checkCharactersEmail()) {
    } else if (!validator.validate(formData.email)) {
      setEmailError('This email address is not valid');
    } else {
      setEmailError(null);
      emailValid = true;
    }

    if (!formData.messageSubject) {
      setMessageSubjectError('Enter a subject');
    } else {
      setMessageSubjectError(null);
      messageSubjectValid = true;
    }

    if (!formData.messageContent) {
      setMessageContentError('Content cannot be empty');
    } else {
      setMessageContentError(null);
      messageContentValid = true;
    }

    if (!recaptchaValue) {
      setRecaptchaError("Please verify that you're not a robot");
    } else {
      recaptchaValid = true;
      setRecaptchaError(null);
    }

    if (formData.phoneNumber) {
      if (formData.phoneNumber.indexOf('+') !== 0) {
        setPhoneNumberError("Don't forget to include the country code");
      } else if (!checkCharactersPhone()) {
      } else {
        //request to check if the phone format (with country code inclusive) is valid
        await axios
          .post('https://finelog.de:4000/verify_phone/', {
            phoneNumber: formData.phoneNumber,
          })
          .then((response) => {
            formData.phoneNumber = response.data.formattedPhoneNumber;
            const valid = response.data.valid;

            if (!valid) {
              setPhoneNumberError('Enter a valid phone number');
            } else {
              setPhoneNumberError(null);
              phoneNumberValid = true;
            }
          }).catch((err) => {
		console.log(err);
		setGoodSentText({
		ok: false,
		text: 'Fehler',
		});
	  });
      }
    } else {
      setPhoneNumberError(null);
      phoneNumberValid = true;
    }

    //add active classes so that the label will actually appear
    setTimeout(() => {
      if (!firstNameValid) {
        firstNameErrorRef.current.classList.add('active');
      }
      if (!lastNameValid) {
        lastNameErrorRef.current.classList.add('active');
      }
      if (!emailValid) {
        emailErrorRef.current.classList.add('active');
      }
      if (!phoneNumberValid) {
        phoneNumberErrorRef.current.classList.add('active');
      }
      if (!messageContentValid) {
        messageContentErrorRef.current.classList.add('active');
      }
      if (!messageSubjectValid) {
        messageSubjectErrorRef.current.classList.add('active');
      }
      if (!recaptchaValid) {
        recaptchaErrorRef.current.classList.add('active');
      }
    }, 50);

    setTimeout(() => {
      const specialInputs = document.querySelectorAll('.special--input');
      const specialSpans = document.querySelectorAll('.special--input--span');

      let firstInputFocused = false;

      specialInputs.forEach((specialInput) => {
        const name = specialInput.getAttribute('name');
        if (
          (name === 'firstName' && !firstNameValid) ||
          (name === 'lastName' && !lastNameValid) ||
          (name === 'email' && !emailValid) ||
          (name === 'messageContent' && !messageContentValid) ||
          (name === 'messageSubject' && !messageSubjectValid) ||
          (name === 'phoneNumber' && !phoneNumberValid)
        ) {
          if (!firstInputFocused) {
            //we want to focus the first input that has been marked as invalid
            firstInputFocused = true;
            if (specialInput !== document.activeElement) {
              specialInput.focus();
            }
          }
          specialInput.style.setProperty('--borderColor', 'rgb(220 38 38 / 1)');
          specialInput.style.setProperty('--normalBorderColor', 'rgb(220 38 38 / 1)');
          specialInput.style.setProperty('--hoverColor', 'rgb(153 27 27)');
          specialInput.style.setProperty('--focusBorderColor', 'rgb(220 38 38 / 1)'); //styles for invalid input
        } else {
          specialInput.style.setProperty('--borderColor', '#1a73e8');
          specialInput.style.setProperty('--normalBorderColor', '#888c8f');
          specialInput.style.setProperty('--hoverColor', '#161616');
          specialInput.style.setProperty('--focusBorderColor', 'rgb(249 115 22 / 1)'); //styles for valid input
        }
      });

      specialSpans.forEach((specialSpan) => {
        const name = specialSpan.getAttribute('name');
        if (
          (name === 'firstName' && !firstNameValid) ||
          (name === 'lastName' && !lastNameValid) ||
          (name === 'email' && !emailValid) ||
          (name === 'messageContent' && !messageContentValid) ||
          (name === 'messageSubject' && !messageSubjectValid) ||
          (name === 'phoneNumber' && !phoneNumberValid)
        ) {
          specialSpan.style.setProperty('--fontColor', 'rgb(220 38 38 / 1)');
          specialSpan.style.setProperty('--normalFontColor', 'rgb(220 38 38 / 1)');
          specialSpan.style.setProperty('--hoverColor', 'rgb(153 27 27)'); //styles for invalid input span
        } else {
          specialSpan.style.setProperty('--fontColor', 'rgb(249 115 22 / 1)');
          specialSpan.style.setProperty('--normalFontColor', '#5f6368');
          specialSpan.style.setProperty('--hoverColor', '#161616'); //styles for valid input span
        }
      });
    }, 50);

    if (
      !firstNameValid ||
      !lastNameValid ||
      !emailValid ||
      !phoneNumberValid ||
      !messageSubjectValid ||
      !messageContentValid ||
      !recaptchaValid
    ) {
      return false;
    }

    return true;
  }

  async function handleSend() {
    const validated = await validateInputs();
    if (!validated) {
      return;
    }

    try {
      //first we have to check if the recaptcha got verified successfuly
      await axios
        .post('https://finelog.de:4000/recaptcha/', {
          response: recaptchaValue,
        })
        .then(async (response) => {
          if (response.data.success === true) {
            messageContentRef.current.value = '';
            messageSubjectRef.current.value = '';
            firstNameRef.current.value = '';
            lastNameRef.current.value = '';
            emailRef.current.value = '';
            phoneNumberRef.current.value = '';
            //if it did then we are trying to send an email
            await axios
              .post('https://finelog.de:4000/send_mail/', {
                text: formData.messageContent,
                fromEmail: formData.email,
                mailSubject: formData.messageSubject,
                phoneNumber: formData.phoneNumber,
                firstName: formData.firstName,
                lastName: formData.lastName,
              })
              .then((response) => {
                setFormData({
                  firstName: '',
                  lastName: '',
                  email: '',
                  phoneNumber: '',
                  messageContent: '',
                  messageSubject: '',
                });
                if (response.data.success) {
                  setGoodSentText({
                    ok: true,
                    text: 'gesendet',
                  });
                } else {
                  setGoodSentText({
                    ok: false,
                    text: 'Fehler',
                  });
                }
              });
          } else {
            setRecaptchaError('Fehler');
          }
        });
    } catch (err) {
      console.log(err);
      setGoodSentText({
        ok: false,
        text: 'Fehler',
      });
    }
  }

  useEffect(() => {
    if (goodSentText.text) {
      emailSuccessRef.current.classList.add('active');
      setTimeout(() => {
        emailSuccessRef.current.classList.remove('active');
      }, 5000); //this will trigger the animation for the slider that comes in with info about success of the operation
      //wether the email has been sent successfuly or not
    }
  }, [goodSentText]);

  return (
    <>
      <Header activePage={props.activePage} setActivePage={props.setActivePage} />
      <main className='h-auto site--content--sm'>
        <div className='w-full page--main--image--holder overflow-hidden'>
          <img src={City} className='kontakt--main--image object-cover'></img>
        </div>
        <div className='w-[80%] mx-auto mt-10 outer--contact'>
          <div className='w-full text-center mb-5'>
            <h1 className='text-5xl font-semibold company--name--contact'>
              <span className='text-[#f97316]'>Fine</span>
              <span className='text-[#666666]'>log</span> GmbH
            </h1>
          </div>
          <div className='h-1 w-full bg-logo-orange rounded-xl'></div>
          <div className='contact--container mt-5 overflow-x-hidden'>
            <section data-aos='fade-down' className='quick--contact--section'>
              <h1 className='text-xl font-medium tracking-ultrawide mb-2 basic--info--title'>
                Telefonnummer
              </h1>
              <div className='flex items-start basic--info--holder'>
                <div className='rounded-full bg-logo-orange basic--info--icon--holder p-3'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6 basic--info--icon'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z'
                    />
                  </svg>
                </div>
                <div className='ml-4 my-auto contact--details--holder'>
                  <p className=''><b>0571 386003 41</b></p>
                </div>
              </div>
            </section>
            <section data-aos='fade-down' className='quick--contact--section'>
              <h1 className='text-xl font-medium tracking-ultrawide mb-2 basic--info--title'>
                E-mail
              </h1>
              <div className='flex items-start basic--info--holder'>
                <div className='rounded-full bg-logo-orange basic--info--icon--holder p-3'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6 basic--info--icon'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z'
                    />
                  </svg>
                </div>
                <div className='ml-4 my-auto contact--details--holder'>
                  <p>info@finelog.de</p>
                  <p>dw@finelog.de</p>
                </div>
              </div>
            </section>
            <section data-aos='fade-down' className='quick--contact--section'>
              <h1 className='text-xl font-medium tracking-ultrawide mb-2 basic--info--title'>
                Firmenangaben
              </h1>
              <div className='flex items-start basic--info--holder'>
                <div className='rounded-full bg-logo-orange basic--info--icon--holder p-3'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6 basic--info--icon'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z'
                    />
                  </svg>
                </div>
                <div className='ml-4 my-auto contact--details--holder'>
                  <p>HRB: 14773</p>
                  <p>Steuernummer: 335/5719/6261</p>
                  <p>MwSt no: DE303840346</p>
                  <p>Betriebsnummer: 96 15 42 80</p>
                </div>
              </div>
            </section>
          </div>

          <section className='mt-10'>
            <div className='grid grid-cols-2 grid--map'>
              <div className='flex flex-col justify-evenly'>
                <div className='further--info' data-aos='fade-right'>
                  <h1 className='text-4xl text-logo-orange font-medium mb-3 further--info--title'>
                    Adresse
                  </h1>
                  <p>Finelog GmbH</p>
                  <p>Zechenstrasse 1-4</p>
                  <p>D-32457 Porta Westfalica</p>
                  <p>Deutschland</p>
                </div>
              </div>
              <div className='flex justify-end googlem--holder' data-aos='fade-left'>
                <iframe
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2442.6984394318374!2d9.005395315696525!3d52.2488608797638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47ba7099a4dac573%3A0x20ef1ee69a33fa69!2sZechenstra%C3%9Fe%201%2C%2032457%20Porta%20Westfalica%2C%20Germany!5e0!3m2!1sen!2spl!4v1651176255636!5m2!1sen!2spl'
                  width='600'
                  height='450'
                  allowfullscreen=''
                  loading='lazy'
                  referrerpolicy='no-referrer-when-downgrade'
                ></iframe>
              </div>
            </div>
          </section>
          <section className='input--form' data-aos='fade-down'>
            <div className='flex gap-10 mt-20 justify-between names--input--container'>
              <div className='flex-grow pt-7 form--input--holder'>
                <div className='w-full h-[56px] relative'>
                  <input
                    type='text'
                    onChange={handleChange}
                    className='special--input'
                    name='firstName'
                    ref={firstNameRef}
                    onKeyDown={keyPressed}
                    required
                  ></input>
                  <div className='special--input--span not--capitalize' name='firstName'>
                    Vorname
                  </div>
                </div>
                {firstNameError && (
                  <div
                    ref={firstNameErrorRef}
                    className='text-xs  flex gap-2 mt-1 text-red-600 error--label pointer-events-none'
                  >
                    <svg
                      fill='currentColor'
                      focusable='false'
                      width='16px'
                      height='16px'
                      viewBox='0 0 24 24'
                    >
                      <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z'></path>
                    </svg>
                    {firstNameError}
                  </div>
                )}
              </div>
              <div className='flex-grow pt-7 form--input--holder'>
                <div className='w-full h-[56px] relative'>
                  <input
                    type='text'
                    onChange={handleChange}
                    className='special--input'
                    name='lastName'
                    ref={lastNameRef}
                    onKeyDown={keyPressed}
                    required
                  ></input>
                  <div className='special--input--span not--capitalize' name='lastName'>
                    Nachname
                  </div>
                </div>
                {lastNameError && (
                  <div
                    ref={lastNameErrorRef}
                    className='text-xs  flex gap-2 mt-1 text-red-600 error--label pointer-events-none'
                  >
                    <svg
                      fill='currentColor'
                      focusable='false'
                      width='16px'
                      height='16px'
                      viewBox='0 0 24 24'
                    >
                      <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z'></path>
                    </svg>
                    {lastNameError}
                  </div>
                )}
              </div>
            </div>

            <div className='grid grid-cols-3 grid-rows-1 gap-10 justify-between sender--info--container'>
              <div className='flex-grow pt-7 form--input--holder'>
                <div className='w-full h-[56px] relative'>
                  <input
                    type='text'
                    onChange={handleChange}
                    className='special--input'
                    name='email'
                    ref={emailRef}
                    onKeyDown={keyPressed}
                    required
                  ></input>
                  <div className='special--input--span not--capitalize' name='email'>
                    Email
                  </div>
                </div>
                {emailError && (
                  <div
                    ref={emailErrorRef}
                    className='text-xs  flex gap-2 mt-1 text-red-600 error--label pointer-events-none'
                  >
                    <svg
                      fill='currentColor'
                      focusable='false'
                      width='16px'
                      height='16px'
                      viewBox='0 0 24 24'
                    >
                      <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z'></path>
                    </svg>
                    {emailError}
                  </div>
                )}
              </div>
              <div className='flex-grow pt-7 form--input--holder'>
                <div className='w-full h-[56px] relative'>
                  <input
                    type='text'
                    onChange={handleChange}
                    className='special--input'
                    name='phoneNumber'
                    ref={phoneNumberRef}
                    onKeyDown={keyPressed}
                    required
                  ></input>
                  <div className='special--input--span not--capitalize' name='phoneNumber'>
                    Telefonnummer
                  </div>
                </div>
                {phoneNumberError && (
                  <div
                    ref={phoneNumberErrorRef}
                    className='text-xs  flex gap-2 mt-1 text-red-600 error--label pointer-events-none'
                  >
                    <svg
                      fill='currentColor'
                      focusable='false'
                      width='16px'
                      height='16px'
                      viewBox='0 0 24 24'
                    >
                      <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z'></path>
                    </svg>
                    {phoneNumberError}
                  </div>
                )}
              </div>
              <div className='flex-grow pt-7 form--input--holder'>
                <div className='w-full h-[56px] relative'>
                  <input
                    type='text'
                    onChange={handleChange}
                    className='special--input'
                    name='messageSubject'
                    ref={messageSubjectRef}
                    onKeyDown={keyPressed}
                    required
                  ></input>
                  <div className='special--input--span not--capitalize' name='messageSubject'>
                    Betreff
                  </div>
                </div>
                {messageSubjectError && (
                  <div
                    ref={messageSubjectErrorRef}
                    className='text-xs  flex gap-2 mt-1 text-red-600 error--label pointer-events-none'
                  >
                    <svg
                      fill='currentColor'
                      focusable='false'
                      width='16px'
                      height='16px'
                      viewBox='0 0 24 24'
                    >
                      <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z'></path>
                    </svg>
                    {messageSubjectError}
                  </div>
                )}
              </div>
            </div>
            <div className='flex mt-5 justify-between'>
              <div className='flex-grow pt-7 form--input--holder'>
                <div className='w-full h-[200px] relative'>
                  <textarea
                    type='text'
                    onChange={handleChange}
                    className='special--input message--content'
                    name='messageContent'
                    ref={messageContentRef}
                    required
                  ></textarea>
                  <div className='special--input--span not--capitalize' name='messageContent'>
                    Nachricht
                  </div>
                </div>
                {messageContentError && (
                  <div
                    ref={messageContentErrorRef}
                    className='text-xs  flex gap-2 mt-1 text-red-600 error--label pointer-events-none'
                  >
                    <svg
                      fill='currentColor'
                      focusable='false'
                      width='16px'
                      height='16px'
                      viewBox='0 0 24 24'
                    >
                      <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z'></path>
                    </svg>
                    {messageContentError}
                  </div>
                )}
              </div>
            </div>
            <div className='mt-10 flex justify-between items-center send--inputs--holder'>
              <div className='flex flex-col recaptcha--holder'>
                <div className='rounded-[10px] overflow-hidden'>
                  <ReCAPTCHA
                    sitekey='6LfSQmcfAAAAAHRF4FX9UVKFK-6dVWg4LH-Bx0ta'
                    onErrored={errorRecaptcha}
                    onChange={recaptchaChange}
                    className='recaptcha'
                    hl='de'
                  />
                </div>
                {recaptchaError && (
                  <div
                    ref={recaptchaErrorRef}
                    className='text-xs  flex gap-2 mt-1 text-red-600 error--label pointer-events-none'
                  >
                    <svg
                      fill='currentColor'
                      focusable='false'
                      width='16px'
                      height='16px'
                      viewBox='0 0 24 24'
                    >
                      <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z'></path>
                    </svg>
                    {recaptchaError}
                  </div>
                )}
              </div>
              <button
                data-aos-offset='-20'
                data-aos-delay='450'
                onClick={handleSend}
                className='px-8 py-2 max-h-[44px] bg-logo-orange text-lg rounded-lg font-medium button--send relative overflow-hidden'
              >
                Senden
              </button>
            </div>
          </section>
        </div>
      </main>
      <div
        ref={emailSuccessRef}
        className={`email--success ${
          goodSentText.ok ? 'bg-green-500' : 'bg-red-600'
        } text-white font-medium text-center pl-1 pr-3 flex items-center`}
      >
        {goodSentText.ok ? (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-12 w-12'
            fill='none'
            viewBox='0 0 24 24'
            stroke='white'
            strokeWidth={2}
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z'
            />
          </svg>
        ) : (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-10 w-10'
            fill='none'
            viewBox='0 0 24 24'
            stroke='white'
            strokeWidth={3}
          >
            <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
          </svg>
        )}
        <i className={`${!goodSentText.ok && 'pr-3'}`}>{goodSentText.text}</i>
      </div>
      <Footer />
    </>
  );
}
