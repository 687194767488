import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import Intersection from '../images/min/intersection-min.jpeg';
import HighwaySunset from '../images/min/highway-sunset-min.jpeg';
import Handshake from '../images/min/handshake-min.jpeg';
import Nature from '../images/min/nature-min.jpeg';
import AOS from 'aos';

export default function Leistungen(props) {
  const setActivePage = props.setActivePage;

  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const switchAttributes = debounce(() => {
    const w = window.innerWidth;

    const middleTextServices = document.querySelector('.middle--text--services');
    const leftTextServices = document.querySelector('.left--text--services');
    const rightTextServices = document.querySelector('.right--text--services');

    const handShakeMiddle = document.querySelector('.text--handshake--middle');
    const handShakeDown = document.querySelector('.text--handshake--down');

    const intersectionTextTopLeft = document.querySelector('.intersection--text--top--left');

    if (w > 900) {
      middleTextServices.setAttribute('data-aos-offset', '0');
    }

    if (w > 900) {
      middleTextServices.setAttribute('data-aos', 'fade-up');
      leftTextServices.setAttribute('data-aos', 'fade-right');
      rightTextServices.setAttribute('data-aos', 'fade-left');

      handShakeDown.setAttribute('data-aos', 'fade-left');
      handShakeMiddle.setAttribute('data-aos', 'fade-left');
      handShakeMiddle.setAttribute('data-aos-delay', '100');
      handShakeDown.setAttribute('data-aos-delay', '200');

      intersectionTextTopLeft.setAttribute('data-aos', 'fade-down');
    } else {
      middleTextServices.setAttribute('data-aos', 'fade-down');
      middleTextServices.setAttribute('data-aos-offset', '-50');

      handShakeMiddle.setAttribute('data-aos', 'fade-right');
      handShakeDown.setAttribute('data-aos', 'fade-left');
      handShakeDown.setAttribute('data-aos-delay', '200');
    }

    setTimeout(() => {
      AOS.refreshHard();
    }, 150);
  }, 250);

  function initialAttributes() {
    const w = window.innerWidth;

    const middleTextServices = document.querySelector('.middle--text--services');
    //	const leftTextServices = document.querySelector('.left--text--services');
    //	const rightTextServices = document.querySelector('.right--text--services');

    const handShakeMiddle = document.querySelector('.text--handshake--middle');
    const handShakeDown = document.querySelector('.text--handshake--down');

    //	const intersectionTextTopLeft = document.querySelector('.intersection--text--top--left');
    const intersectionTextBottomLeft = document.querySelector('.intersection--text--bottom--left');

    if (w < 900) {
      middleTextServices.setAttribute('data-aos', 'fade-down');
      middleTextServices.setAttribute('data-aos-offset', '-50');

      handShakeMiddle.setAttribute('data-aos', 'fade-right');
      handShakeDown.setAttribute('data-aos', 'fade-left');
      handShakeDown.setAttribute('data-aos-delay', '200');
    }

    setTimeout(() => {
      AOS.init({
        offset: 100,
        duration: 850,
      });
    }, 150);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setActivePage('leistungen');
    initialAttributes();
    window.addEventListener('resize', switchAttributes);

    return () => {
      window.removeEventListener('resize', switchAttributes);
    };
  }, []);

  return (
    <>
      <Header activePage={props.activePage} setActivePage={props.setActivePage} />
      <main className='h-auto site--content--sm mb-20'>
        <div className='page--main--image--holder overflow-hidden relative'>
          <img
            loading='lazy'
            src={HighwaySunset}
            className='object-cover w-full leistungen--main--image'
          ></img>
        </div>
        <div className='sm:w-[80%] mx-auto w-[95%]'>
          <section className='mt-10'>
            <div className='services--special--holder text-center text-lg text-logo-gray site--content--text'>
              <p data-aos='fade-right' data-aos-offset='-50px' className='left--text--services'>
                Finelog ist ein Frachtf{'\u00FC'}hrer f{'\u00FC'}r deutsche Spedituere und gro
                {'\u00DF'}e Unternehmen in Deutschland.
              </p>

              <p data-aos='fade-up' className='middle--text--services'>
                Finelog Manager sind im Stande sehr schnell die Flotte an das aktuelle oder gew
                {'\u00FC'}
                nsche Projekt des Kunden individuell anzupassen und st{'\u00E4'}ndig f{'\u00FC'}r
                den Kunden atraktiv, modern und {'\u00F6'}kologisch zu sein.
              </p>

              <p data-aos='fade-left' data-aos-offset='-50px' className='right--text--services'>
                Finelog versucht ihre Flotte permanent nach den Bed{'\u00FC'}rfnissen der Kunden
                anzugleichen.
              </p>
            </div>
          </section>
          <section className='mt-20'>
            <h1 className='text-logo-orange font-medium text-2xl page--title text-center'>
              Finelog ist sehr flexibel und kundenfreundlich
            </h1>
            <div className='services--card--holder mt-10'>
              <div className='w-1/2 image--handshake--holder'>
                <img
                  loading='lazy'
                  src={Handshake}
                  className='rounded-[10px] h-[400px] object-cover'
                  data-aos='zoom-in'
                ></img>
              </div>
              <div className='flex flex-col w-1/2 justify-evenly text-center text-logo-gray handshake--text site--content--text'>
                <p data-aos='fade-left'>
                  Finelog verf{'\u00FC'}gt {'\u00FC'}ber langj{'\u00E4'}hrige Erfahrung auf dem
                  deutschen Transportmarkt und ist Ihr zuverl{'\u00E4'}ssiger Partner im Komplett
                  Ladungsbereich.
                </p>{' '}
                <div className='contents--holder'>
                  <p data-aos='fade-left' className='text--handshake--middle'>
                    Finelog f{'\u00E4'}hrt regelm{'\u00E4'}
                    {'\u00DF'}ige Linien mit Ladungen f{'\u00FC'}r viele deutsche Unternehmen in
                    ganz Deutschland und auch oft in andere europ{'\u00E4'}ische L{'\u00E4'}nder.
                  </p>
                  <p data-aos='fade-left' className='text--handshake--down'>
                    Finelog verf{'\u00FC'}gt {'\u00FC'}ber mehr als 40 Zugmaschinen und Auflieger
                    und kann die Flotte je nach Bedarf st{'\u00E4'}ndig vergr{'\u00F6'}
                    {'\u00DF'}ern und/ oder dringende Maschinen einsetzen.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <div className='bg-logo-orange h-1 w-full mt-10 rounded-full'></div>
          <div className='mt-10'>
            <div className='service--sections--holder text-logo-gray site--content--text'>
              <section className='left--text--section'>
                <div className='flex flex-col justify-evenly h-full service--sections--text--left'>
                  <p data-aos='fade-down' className='intersection--text--top--left'>
                    Die Linien werden auf dem Laufenden optimalisiert und kontrolliert, so dass der
                    Kunde die besten Leistungen erwarten kann.
                  </p>
                </div>
              </section>
              <section className='intersection--image--holder'>
                <img
                  loading='lazy'
                  src={Intersection}
                  className='h-[400px] rounded-[10px] object-cover'
                  data-aos='zoom-in'
                ></img>
              </section>
              <section className='my-auto right--text--section'>
                <p data-aos='fade-up'>
                  Der st{'\u00E4'}ndige Kontakt und feste Relationen mit dem Team sind auch ein
                  guter Bestandteil des Erfolges der Zusammenarbeit sowohl mit den Fahrern als auch
                  vor allem mit den Kunden, die sich auch deswegen mit Finelog sicher f{'\u00FC'}
                  hlen k{'\u00F6'}nnen.
                </p>
              </section>
            </div>
          </div>
          <div className='bg-logo-orange h-1 w-full mt-10 rounded-full'></div>
          <div className='mt-10 flex gap-5 eco--section'>
            <div className='plant--image--holder'>
              <img
                loading='lazy'
                src={Nature}
                className='rounded-[10px] object-cover '
                data-aos='zoom-in'
              ></img>
            </div>
            <p
              className='text-logo-orange text-xl my-auto font-medium text-center'
              data-aos='fade-down'
            >
              F{'\u00FC'}r Finelog {'\u00F6'}kologisch zu sein steht im Mittelpunkt. Wir legen
              darauf gro
              {'\u00DF'}en Wert mit {'\u00D6'}kofahrzeugen zu fahren.
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
