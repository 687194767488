import React from 'react';
import KommodusLogo from '../images/min/kommodus-logo.png';
import PanecoLogo from '../images/min/paneco-logo.png';

export default function Footer() {
  const currentYear = new Date().getUTCFullYear();
  return (
    <>
      <footer className='w-full bg-special-gray '>
        <div className='py-10 w-[90%] mx-auto mt-5 mb-10 footer--grid gap-4'>
          <section>
            <h1 className='text-white text-lg mb-2'>Finelog GmbH</h1>
            <p className='text-[#6c757d]'>Erfahrener Frachtf{'\u00FC'}hrer in Deutschland</p>
          </section>
          <section>
            <h1 className='text-white text-lg mb-2'>Kontakt</h1>
            <div className='text-[#6c757d] flex flex-col gap-3'>
              <div className='flex gap-3 contact--footer'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z'
                  />
                </svg>
                Tel. <b>0571 386003 41</b>
              </div>
              <div className='flex gap-3 contact--footer'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z'
                  />
                </svg>
                E-mail: dw@finelog.de
              </div>
              <div className='flex gap-3 contact--footer'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z'
                  />
                </svg>
                Steuernummer: 335/5719/6261
              </div>
            </div>
          </section>
          <section>
            <h1 className='text-white text-lg mb-2'>Partners</h1>
            <div className='flex flex-col gap-3 text-[#6c757d]'>
              <p>
                <i>Kommodus</i>
              </p>
              <p>
                <i>E.L.V.I.S</i>
              </p>
            </div>
          </section>
          <section>
            <h1 className='text-white text-lg mb-2'>Was machen wir?</h1>
            <div className='text-[#6c757d]'>
              Wir fahren feste Linien f{'\u00FC'}r unsere Kunden.
            </div>
          </section>
        </div>
        <div className='w-[90%] mx-auto border-t flex justify-center footer--copy px-5 pb-5 py-3 border-t-[#343434]'>
          <span className='text-white'>
            &copy; {currentYear} <span className='text-[#f97316]'>Fine</span>
            <span>log</span> | Designed by:{' '}
            <a href='https://phasmasolutions.com/' target='_blank' className='copy--link'>
              <i>Olaf Dalach</i>
            </a>
          </span>
        </div>
      </footer>
    </>
  );
}
