import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import AOS from 'aos';
import Driver from '../images/min/driver-min.jpeg';
import MainImage from '../images/min/logistics-min.jpeg';
import Boxes from '../images/min/boxes-min.jpeg';
import { Link } from 'react-router-dom';
import Texts from '../MainPageTexts';

export default function MainPage(props) {
  const setActivePage = props.setActivePage;

  const [chooserTextIndex, setChooseTextIndex] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    setActivePage('start');
    const buttons = document.querySelectorAll('.text--select--button');
    buttons.forEach((button) => {
      button.classList.remove('selected');
    });
    document.querySelector('.text--select--button').classList.add('selected');
    AOS.init({
      offset: 100,
      duration: 850,
    });
  }, []);

  function handleTextSwitch(e) {
    const index = e.target.value;
    setChooseTextIndex(index);
    document.querySelector('.text--displayer').classList.add('active');

    const buttons = document.querySelectorAll('.text--select--button');
    buttons.forEach((button) => {
      if (button.value === index) {
        button.classList.add('selected');
      } else {
        button.classList.remove('selected');
      }
    });

    setTimeout(() => {
      document.querySelector('.text--displayer').classList.remove('active');
    }, 200);
  }

  return (
    <>
      <Header activePage={props.activePage} setActivePage={props.setActivePage} />
      <main className='h-auto site--content--sm'>
        <div className='w-full page--main--image--holder overflow-hidden relative'>
          <img
            loading='lazy'
            src={MainImage}
            className='object-cover w-full start--main--image'
          ></img>

          <Link to='/uns'>
            <button className='bg-logo-orange px-3 py-2 main--button absolute rounded-md text-white text-2xl hover:bg-orange-600'>
              {'\u00DC'}ber uns
            </button>
          </Link>
        </div>
        <div className='bg-gray-100 py-10'>
          <div className='w-[90%] mx-auto'>
            <div className='w-[90%] mx-auto' data-aos='fade-down'>
              <p className='text-logo-gray text-lg text-center site--content--text'>
                Finelog ist ein perfekter Frachtf{'\u00FC'}hrer f{'\u00FC'}r Sie und bietet Ihnen M
                {'\u00F6'}
                glichkeit, Ihre logistischen und speditionellen Herausforderungen individuell und
                flexibel zu l{'\u00F6'}sen. Finelog f{'\u00E4'}hrt f{'\u00FC'}r die Kunden regelm
                {'\u00E4'}
                {'\u00DF'}ige Linien, die zeitgerecht ans Ziel kommen und die Erwartungen der Kunden
                erf
                {'\u00FC'}llen.
              </p>
            </div>
          </div>
          <section>
            <div className='mt-20 start--page--grid relative'>
              <div className='flex rounded-[10px] overflow-hidden min-h-2/3 text--chooser--holder w-[80%] mx-auto absolute z-50'>
                <div
                  className='flex flex-col bg-logo-orange border-0 text-white w-[50%] text-xl text--chooser'
                  data-aos='fade-down'
                >
                  <button onClick={handleTextSwitch} value='0' className='text--select--button'>
                    Was ist Finelog?
                  </button>
                  <button onClick={handleTextSwitch} value='1' className='text--select--button'>
                    Finelog f{'\u00FC'}r Sie
                  </button>
                </div>

                <div
                  className='bg-orange-100 h-full text-sm whitespace-pre-line text--displayer w-full px-3 py-5 text-logo-gray'
                  data-aos='fade-down'
                  data-aos-anchor='.text--chooser'
                >
                  <p>{Texts[chooserTextIndex]}</p>
                </div>
              </div>

              <img
                src={Driver}
                className='driver--image object-cover h-[500px] opacity-50 w-full'
              ></img>
            </div>
          </section>
        </div>
        <section>
          <div className='w-[90%] mx-auto my-20'>
            <div
              className='text-center text-lg text-logo-gray site--content--text'
              data-aos='fade-down'
            >
              <p>
                Dank unserer langj{'\u00E4'}hrigen Erfahrung im St{'\u00FC'}ckgutverkehr sorgen wir
                jeden Tag aufs Neue daf
                {'\u00FC'}r, dass Ihre Sendungen p{'\u00FC'}nktlich den Bestimmungsort erreichen.
              </p>
              <div>
                <p>
                  Der Autotyp wird immer mit Ihnen abgestimmt und nach Ihren Bed{'\u00FC'}rfnissen
                  angepasst.
                </p>
                <p>
                  Wir verf{'\u00FC'}gen {'\u00FC'}ber mehr als 40 Lastkraftwagen und fahren tagt
                  {'\u00E4'}glich f{'\u00FC'}r unsere Kundschaft in ganz Deutschland.
                </p>
              </div>
              <p>
                Unsere Kunden stammen aus unterschiedlichen Branchen und wir haben deswegen
                interessante und wertvolle Erfahrung, die unsere Kunden jeden Tag sehen und nutzen k
                {'\u00F6'}nnen.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className='h-30 w-full relative'>
            <img src={Boxes} className='object-cover img--boxes w-full h-[300px]'></img>
            <div className='boxes--content--holder flex justify-between w-full absolute'>
              <p className='text-5xl text-white text--boxes' data-aos='zoom-out-down'>
                Leistungen
              </p>
              <Link to='/leistungen'>
                <button
                  className=' text-white button--boxes text-2xl px-4 py-2 bg-black rounded-[5px]'
                  data-aos='zoom-out-down'
                >
                  <i>Testen Sie uns</i>
                </button>
              </Link>
            </div>
          </div>
        </section>
        <section>
          <div
            className='text-logo-gray text-xl w-[90%] mx-auto my-20 text-center site--content--text'
            data-aos='fade-down'
          >
            <p>
              Der Autotyp wird immer mit Ihnen abgestimmt und nach Ihren Bed{'\u00FC'}rfnissen
              angepasst.
            </p>
            <p>
              Wir verf{'\u00FC'}gen {'\u00FC'}ber mehr als 40 Lastkraftwagen und fahren tagt
              {'\u00E4'}glich f{'\u00FC'}r unsere Kundschaft in ganz Deutschland.{' '}
            </p>

            <p>
              Unsere Flexibilit{'\u00E4'}t unterscheidet sich von anderen, denn wir arbeiten offen
              gegn{'\u00FC'}
              ber dem Kunden und versuchen immer mit fertigen, angepassten L{'\u00F6'}sungen auf ihn
              zu kommen.
            </p>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

/*

*/
