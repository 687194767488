import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import pageLogo from '../images/finelog-logo-transparent.png';

export default function Header(props) {
  const [isOpen, setIsOpen] = useState(false);
  const activePage = props.activePage;

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const header = document.querySelector('.page--header');
      header.classList.toggle('sticky', window.scrollY > 20);
    });

    document.getElementById('overlay').addEventListener('click', () => {
      handleCloseModal();
    });
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.querySelector('.barToggler').classList.add('open');
      document.querySelector('html').style.overflowY = 'hidden';
    } else {
      document.querySelector('.barToggler').classList.remove('open');
      document.querySelector('html').style.overflowY = 'visible';
    }
  }, [isOpen]);

  function toggleBar() {
    setIsOpen((prev) => !prev);
    document.querySelector('.modal').classList.toggle('active');
    document.getElementById('overlay').classList.toggle('active');
  }

  function handleCloseModal() {
    setIsOpen(false);
    document.querySelector('.modal').classList.remove('active');
    document.getElementById('overlay').classList.remove('active');
    document.querySelector('html').style.overflowY = 'visible';
  }

  return (
    <>
      <div className='modal flex py-20 items-start'>
        <div
          className='absolute top-5 right-5 cursor-pointer close--modal p-1 rounded-full hover:bg-zinc-100'
          onClick={handleCloseModal}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-6 w-6'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
            strokeWidth={2}
          >
            <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
          </svg>
        </div>
        <ul className='flex flex-col gap-3 mb-20 flex-grow justify-end text-2xl tracking-tight header--list list--modal'>
          <li className='cursor-pointer'>
            <label
              className={`header--label ${activePage === 'start' ? 'text-[#f97316]' : ''}`}
              data-text={'Start'}
            >
              <Link to='/'>{'Start'}</Link>
            </label>
          </li>
          <li className='cursor-pointer'>
            <label
              className={`header--label ${activePage === 'uns' ? 'text-[#f97316]' : ''}`}
              data-text={'\u00DCber Uns'}
            >
              <Link to='/uns'>{'\u00DCber Uns'}</Link>
            </label>
          </li>

          <li className='cursor-pointer'>
            <label
              className={`header--label ${activePage === 'leistungen' ? 'text-[#f97316]' : ''}`}
              data-text='Leistungen'
            >
              <Link to='/leistungen'>Leistungen</Link>
            </label>
          </li>
          <li className='cursor-pointer'>
            <label
              className={`header--label ${activePage === 'oko' ? 'text-[#f97316]' : ''}`}
              data-text={'\u00D6ko Finelog'}
            >
              <Link to='/oko-finelog'>{'\u00D6ko Finelog'}</Link>
            </label>
          </li>
          <li className='cursor-pointer'>
            <label
              className={`header--label ${activePage === 'partnerschaft' ? 'text-[#f97316]' : ''}`}
              data-text='Partnerschaft'
            >
              <Link to='/partnerschaft'>Partnerschaft</Link>
            </label>
          </li>
          <li className='cursor-pointer'>
            <label
              className={`header--label ${activePage === 'kontakt' ? 'text-[#f97316]' : ''}`}
              data-text='Kontakt'
            >
              <Link to='/kontakt'>Kontakt</Link>
            </label>
          </li>
        </ul>
      </div>
      <div id='overlay'></div>

      <header className='w-full h-auto page--header'>
        <div className='pr-5 pl-8 py-6 flex items-center justify-between header--content'>
          <div className=''>
            <Link to='/'>
              <img src={pageLogo}></img>
            </Link>
          </div>
          <div className='mr-1 flex items-center gap-5'>
            <ul className='flex flex-row header--list justify-end text-xl tracking-tight'>
              <li className='cursor-pointer'>
                <label
                  className={`header--label ${activePage === 'start' ? 'text-[#f97316]' : ''}`}
                  data-text={'Start'}
                >
                  <Link to='/'>{'Start'}</Link>
                </label>
              </li>
              <li className='cursor-pointer'>
                <label
                  className={`header--label ${activePage === 'uns' ? 'text-[#f97316]' : ''}`}
                  data-text={'\u00DCber Uns'}
                >
                  <Link to='/uns'>{'\u00DCber Uns'}</Link>
                </label>
              </li>

              <li className='cursor-pointer'>
                <label
                  className={`header--label ${activePage === 'leistungen' ? 'text-[#f97316]' : ''}`}
                  data-text='Leistungen'
                >
                  <Link to='/leistungen'>Leistungen</Link>
                </label>
              </li>
              <li className='cursor-pointer'>
                <label
                  className={`header--label ${activePage === 'oko' ? 'text-[#f97316]' : ''}`}
                  data-text={'\u00D6ko Finelog'}
                >
                  <Link to='/oko-finelog'>{'\u00D6ko Finelog'}</Link>
                </label>
              </li>
              <li className='cursor-pointer'>
                <label
                  className={`header--label ${
                    activePage === 'partnerschaft' ? 'text-[#f97316]' : ''
                  }`}
                  data-text='Partnerschaft'
                >
                  <Link to='/partnerschaft'>Partnerschaft</Link>
                </label>
              </li>
              <li className='cursor-pointer'>
                <label
                  className={`header--label ${activePage === 'kontakt' ? 'text-[#f97316]' : ''}`}
                  data-text='Kontakt'
                >
                  <Link to='/kontakt'>Kontakt</Link>
                </label>
              </li>
            </ul>
            <div
              className='barToggler flex justify-center items-center cursor-pointer'
              onClick={toggleBar}
            >
              <div className='barToggler--button bg-orange-400 after:bg-orange-400 before:bg-orange-400'></div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
