import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import Plant from '../images/min/plant-min.jpeg';
import MainImage from '../images/min/eco-forest-min.jpeg';
import TruckSky from '../images/min/truck-sky.jpg';
import HighwayTrucks2 from '../images/min/highway_trucks2-min.jpeg';
import AOS from 'aos';

export default function OkoFinelog(props) {
  const setActivePage = props.setActivePage;
  useEffect(() => {
    window.scrollTo(0, 0);
    setActivePage('oko');
    setTimeout(() => {
      AOS.init({
        offset: 100,
        duration: 850,
      });
    }, 150);
  }, []);

  return (
    <>
      <Header activePage={props.activePage} setActivePage={props.setActivePage} />
      <main className='h-auto site--content--sm mb-40'>
        <div className='w-full page--main--image--holder overflow-hidden'>
          <img
            loading='lazy'
            src={MainImage}
            className='object-cover w-full eco--main--image'
          ></img>
        </div>
        <div className='w-[90%] mx-auto site--top'>
          <div className='text-center mt-20'>
            <p
              className='text-logo-gray font-semibold page--title text-lg px-4'
              data-aos='fade-down'
            >
              {'\u00D6'}kotransporte sind diejenigen, die unsere Atmosphere nicht verschmutzen. Wir
              sind stolz darauf, dass unsere Fahrzeuge, mit LNG Gas betrieben werden und damit auch
              zur Umweltschutz der Welt erfolgsam beitragen.
            </p>
          </div>
          <div className='lng--gas--info mt-20'>
            <section>
              <div className='lng--gas--card'>
                <div>
                  <div className='text-logo-gray' data-aos='fade-down' data-aos-delay='200'>
                    <h1 className='text-3xl leading-10 text-logo-orange font-medium section--title'>
                      Wieso ist es {'\u00F6'}kologisch, Fahrzeuge mit dem LNG Gas zu betreiben und
                      was ist eigentlich LNG (Liquefied Natural Gas)?
                    </h1>
                    <p className='mt-10 smaller--margin--top site--content--text'>
                      Wird Erdgas unter atmosph{'\u00E4'}rischem Druck auf Temperaturen von -160
                      {'\u00B0'}C heruntergek
                      {'\u00FC'}hlt, entsteht dabei Fl{'\u00FC'}ssigerdgas, auch bekannt als
                      Liquefied Natural Gas (LNG). Der Vorteil dieses Fl{'\u00FC'}ssiggases gegen
                      {'\u00FC'}ber Erdgas ist das Volumen, das durch die Ver{'\u00E4'}nderung des
                      Aggregatzustands von gasf
                      {'\u00F6'}rmig in fl{'\u00FC'}ssig entsteht: es ist 600fach geringer als bei
                      Erdgas. So kann es einfach in gr{'\u00F6'}
                      {'\u00DF'}eren Mengen gespeichert werden.
                    </p>
                  </div>
                  <div
                    className='lng--gas--text mt-10 text-logo-gray'
                    data-aos='fade-down'
                    data-aos-delay='200'
                  >
                    <h1 className='text-logo-orange mt-10 font-medium text-3xl section--title'>
                      Neben den Volumenmerkmalen besitzt LNG folgende Eigenschaften:
                    </h1>
                    <ul className='site--list site--content--text lowercase'>
                      <li>
                        <i>Klar</i>
                      </li>
                      <li>
                        <i>Farblos</i>
                      </li>
                      <li>
                        <i>Nicht korrosiv</i>
                      </li>
                      <li>
                        <i>Ungiftig</i>
                      </li>
                      <li>
                        <i>Geruchsneutral</i>
                      </li>
                      <li className='normal-case'>
                        <i>steht nicht unter Druck</i>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='' data-aos='zoom-in' data-aos-delay='200'>
                  <img
                    loading='lazy'
                    src={Plant}
                    className='rounded-[10px] w-full object-cover plant--image'
                  ></img>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className='bg-gray-100'>
          <section>
            <div className='lng--continued bg-gray-100 px-[5%] pb-5 my-20'>
              <div className='lng--continued--text'>
                <div className='flex flex-col gap-4' data-aos='fade-down' data-aos-delay='200'>
                  <h1 className='text-logo-orange mt-10 font-medium text-3xl section--title'>
                    Wo wird LNG gef{'\u00F6'}rdert?
                  </h1>
                  <p className='text-logo-gray site--content--text'>
                    LNG kann dort gef{'\u00F6'}rdert werden, wo es ein gro{'\u00DF'}es Vorkommen von
                    Erdgas gibt. F{'\u00FC'}r den europ{'\u00E4'}ischen Bedarf wird es haupts
                    {'\u00E4'}chlich aus Russland, USA und Katar importiert. Durch Bohrungen werden
                    die Gaslagerst{'\u00E4'}tten erschlossen und so das in den Steinen befindliche
                    Erdgas freigesetzt, was anschlie{'\u00DF'}end aufbereitet und transportiert
                    werden kann. Durch die entsprechende K{'\u00FC'}hlung des Erdgases entsteht LNG.
                  </p>
                </div>
                <div className='flex flex-col gap-4' data-aos='fade-down' data-aos-delay='200'>
                  <h1 className='text-logo-orange mt-10 font-medium text-3xl section--title'>
                    Worin unterscheiden sich Liquefied Natural Gas und Liquefied Petroleum Gas?
                  </h1>
                  <p className='text-logo-gray site--content--text'>
                    Leicht k{'\u00F6'}nnen die beiden Gase aufgrund ihrer {'\u00E4'}hnlichen Abk
                    {'\u00FC'}rzungen verwechselt werden, doch es gibt entscheidende Unterschiede
                    zwischen dem Liquefied Petroleum Gas (LPG), das auch als Autogas bekannt ist,
                    und dem Fl{'\u00FC'}
                    ssigerdgas LNG.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className='mt-5 table--section w-[90%] mx-auto relative'>
              <div className='truck--sky--holder' data-aos='fade-right' data-aos-offset='200'>
                <img
                  loading='lazy'
                  src={HighwayTrucks2}
                  className='rounded-[10px] h-full object-cover w-full'
                ></img>
              </div>
              <div className='table--grid mx-4' data-aos='fade-left'>
                <div className='table--row'>
                  <div></div>
                  <div className='table--title'>LNG</div>
                  <div className='table--title'>LPG</div>
                  <div className='table--title'>CNG</div>
                </div>
                <div className='table--row'>
                  <div></div>
                  <div className='table--title'>Liquefied Natural Gas</div>
                  <div className='table--title'>Liquefied Petroleum Gas</div>
                  <div className='table--title'>Compressed Natural Gas</div>
                </div>
                <div className='table--row'>
                  <div className='table--title'>Zusammensetzung</div>
                  <div className='table--text'>Haupts{'\u00E4'}chlich aus Methan</div>
                  <div className='table--text'>Butan und Propan</div>
                  <div className='table--text'>Methan, Stickstoff, Kohlendioxid</div>
                </div>
                <div className='table--row'>
                  <div className='table--title'>Verwendung</div>
                  <div className='table--text'>
                    Heizen, Kochen, Kraftstoff f{'\u00FC'}r Fahrzeuge
                  </div>
                  <div className='table--text'>Private und kommerzielle Verwendung als Autogas</div>
                  <div className='table--text'>Kraftstoff f{'\u00FC'}r Erdgasfahrzeuge</div>
                </div>
                <div className='table--row'>
                  <div className='table--title'>Lagerung</div>
                  <div className='table--text'>Bei Niedrigtemperatur</div>
                  <div className='table--text'>Unter hohem Druck</div>
                  <div className='table--text'>Nicht tiefkalt gelagert </div>
                </div>
                <div className='table--row'>
                  <div className='table--title'>Eigenschaften</div>
                  <div>
                    <ul className='site--list text-[14px] site--content--text'>
                      <li>Leichter als Luft, verteilt und verd{'\u00FC'}nnt sich schnell</li>
                      <li>
                        Fl{'\u00FC'}ssig bei atmosph{'\u00E4'}rischem Druck und sehr niedrigen
                        Temperaturen (-160 Grad)
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ul className='site--list text-[14px] site--content--text'>
                      <li>Schwerer als Luft, Gas verteilt sich nicht, wenn es austritt</li>
                      <li>
                        Fl{'\u00FC'}ssig unter hohem Druck bei Umgebungstemperatur oder unter
                        Atmosph
                        {'\u00E4'}rendruck bei -1 bis -42 Grad
                      </li>
                    </ul>
                  </div>
                  <div className='site--content--text'>
                    Ungiftig, brennbar, farb- und in der Regel geruchlos. Z{'\u00FC'}ndtemperatur
                    von rund 600
                    {'\u00B0'}C. leichter als Luft.
                  </div>
                </div>
              </div>
              <div className='table--grid2 mx-4' data-aos='fade-left'>
                <div>
                  <div className='table2--main--title'>
                    <i>
                      LNG <span className='text-sm'>(Liquefied Natural Gas)</span>
                    </i>
                  </div>
                  <div className='flex flex-col gap-2 mt-5'>
                    <div className='table--title'>Zusammensetzung</div>
                    <div className='table--text'>Haupts{'\u00E4'}chlich aus Methan</div>
                    <div className='table--title'>Verwendung</div>
                    <div className='table--text'>
                      Heizen, Kochen, Kraftstoff f{'\u00FC'}r Fahrzeuge
                    </div>
                    <div className='table--title'>Lagerung</div>
                    <div className='table--text'>Bei Niedrigtemperatur</div>
                    <div className='table--title'>Eigenschaften</div>
                    <div>
                      <ul className='site--list text-[14px]'>
                        <li>Leichter als Luft, verteilt und verd{'\u00FC'}nnt sich schnell</li>
                        <li>
                          Fl{'\u00FC'}ssig bei atmosph{'\u00E4'}rischem Druck und sehr niedrigen
                          Temperaturen (-160 Grad)
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='mt-8'>
                  <div className='table2--main--title'>
                    <i>
                      LPG <span className='text-sm'>(Liquefied Petroleum Gas)</span>
                    </i>
                  </div>
                  <div className='flex flex-col gap-2 mt-5'>
                    <div className='table--title'>Zusammensetzung</div>
                    <div className='table--text'>Butan und Propan</div>
                    <div className='table--title'>Verwendung</div>
                    <div className='table--text'>
                      Private und kommerzielle Verwendung als Autogas
                    </div>
                    <div className='table--title'>Lagerung</div>
                    <div className='table--text'>Unter hohem Druck</div>
                    <div className='table--title'>Eigenschaften</div>
                    <div>
                      <ul className='site--list text-[14px]'>
                        <li>Schwerer als Luft, Gas verteilt sich nicht, wenn es austritt</li>
                        <li>
                          Fl{'\u00FC'}ssig unter hohem Druck bei Umgebungstemperatur oder unter
                          Atmosph
                          {'\u00E4'}rendruck bei -1 bis -42 Grad
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='mt-8'>
                  <div className='table2--main--title'>
                    <i>
                      CNG <span className='text-sm'>(Compressed Natural Gas)</span>
                    </i>
                  </div>
                  <div className='flex flex-col gap-2 mt-5'>
                    <div className='table--title'>Zusammensetzung</div>
                    <div className='table--text'>Methan, Stickstoff, Kohlendioxid</div>
                    <div className='table--title'>Verwendung</div>
                    <div className='table--text'>Kraftstoff f{'\u00FC'}r Erdgasfahrzeuge</div>
                    <div className='table--title'>Lagerung</div>
                    <div className='table--text'>Nicht tiefkalt gelagert</div>
                    <div className='table--title'>Eigenschaften</div>
                    <div className='table--text'>
                      Ungiftig, brennbar, farb- und in der Regel geruchlos. Z{'\u00FC'}ndtemperatur
                      von rund 600
                      {'\u00B0'}C. leichter als Luft.
                    </div>
                  </div>
                </div>
              </div>
              <label
                className='flex gap-1 pr-4 justify-end float-right mt-2 pb-2 quelle--label'
                data-aos='fade-left'
                data-aos-anchor='.table--grid'
              >
                Quelle:
                <a href='https://barmalgas.de' target='_blank' className='text-logo-orange'>
                  <i>Link</i>
                </a>
              </label>
            </div>
          </section>
        </div>
        <section>
          <div
            className='flex flex-col justify-center items-center gap-5 mt-10 equipment--holder'
            data-aos='fade-down'
          >
            <h1 className='text-logo-orange mt-10 font-medium text-3xl section--title'>
              Equipment
            </h1>
            <p className='text-center text-logo-gray w-4/5 site--content--text text-2xl'>
              Finelog f{'\u00E4'}hrt die Ladungen f{'\u00FC'}r ihre Kunden haupts{'\u00E4'}
              chtlich mit {'\u00F6'}kologischen LNG - Zugmaschinen, die viel weniger die Umwelt
              besch
              {'\u00E4'}digen.
            </p>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
