import React from 'react';

export default function Arrow() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='h-6 w-6 double--arrow'
      fill='none'
      viewBox='0 0 24 24'
      stroke='rgb(249 115 22 / 1)'
      strokeWidth={3}
    >
      <path strokeLinecap='round' strokeLinejoin='round' d='M13 5l7 7-7 7M5 5l7 7-7 7' />
    </svg>
  );
}
