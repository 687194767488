import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import Profiles from '../images/min/profiles-min.jpeg';
import Parking from '../images/min/parking-top.jpg';
import Highway from '../images/min/highway_trucks.jpg';
import HighwayBlur from '../images/min/highway-blur.jfif';
import ElvisLogo from '../images/min/elvis-logo.webp';
import AOS from 'aos';

export default function Partnerschaft(props) {
  const setActivePage = props.setActivePage;
  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const switchAttributes = debounce(() => {
    const w = window.innerWidth;

    const card1 = document.getElementById('card1');
    const card2 = document.getElementById('card2');
    const card3 = document.getElementById('card3');

    const card1Image = document.getElementById('card1image');
    const card2Image = document.getElementById('card2image');
    const card3Image = document.getElementById('card3image');

    const card1Text = document.getElementById('card1text');
    const card2Text = document.getElementById('card2text');
    const card3Text = document.getElementById('card3text');

    if (w > 950) {
      //resetting all of the attributes for other elements as we want to animate the whole card
      card1Image.setAttribute('data-aos', '');
      card1Image.setAttribute('data-aos-delay', '');
      card1Text.setAttribute('data-aos', '');
      card2Image.setAttribute('data-aos', '');
      card2Image.setAttribute('data-aos-delay', '');
      card2Text.setAttribute('data-aos', '');
      card3Image.setAttribute('data-aos', '');
      card3Image.setAttribute('data-aos-delay', '');
      card3Text.setAttribute('data-aos', '');
    }

    if (w > 1200) {
      card1.setAttribute('data-aos', 'fade-down');
      card2.setAttribute('data-aos', 'fade-up');
      card2.setAttribute('data-aos-delay', '400');
      card3.setAttribute('data-aos-delay', '800');
      card3.setAttribute('data-aos', 'fade-down');
    } else if (w < 1200 && w > 950) {
      card1.setAttribute('data-aos', 'fade-right');
      card2.setAttribute('data-aos', 'fade-left');
      card2.setAttribute('data-aos-delay', '0');
      card2.setAttribute('data-aos-delay', '0');
      card3.setAttribute('data-aos', 'fade-right');
    } else {
      card1.setAttribute('data-aos', '');
      card2.setAttribute('data-aos', '');
      card3.setAttribute('data-aos', '');

      card1Image.setAttribute('data-aos', 'fade-left');
      card1Text.setAttribute('data-aos', 'fade-right');

      card2Image.setAttribute('data-aos', 'fade-left');
      card2Text.setAttribute('data-aos', 'fade-right');

      card3Image.setAttribute('data-aos', 'fade-left');
      card3Text.setAttribute('data-aos', 'fade-right');
    }
    setTimeout(() => {
      AOS.refreshHard();
    }, 150);
  }, 250);

  function initialAttributes() {
    const w = window.innerWidth;

    const card1 = document.getElementById('card1');
    const card2 = document.getElementById('card2');
    const card3 = document.getElementById('card3');

    const card1Image = document.getElementById('card1image');
    const card2Image = document.getElementById('card2image');
    const card3Image = document.getElementById('card3image');

    const card1Text = document.getElementById('card1text');
    const card2Text = document.getElementById('card2text');
    const card3Text = document.getElementById('card3text');

    if (w > 1200) {
      card1.setAttribute('data-aos', 'fade-down');
      card2.setAttribute('data-aos', 'fade-up');
      card2.setAttribute('data-aos-delay', '400');
      card3.setAttribute('data-aos-delay', '800');
      card3.setAttribute('data-aos', 'fade-down');
    } else if (w < 1200 && w > 950) {
      card1.setAttribute('data-aos', 'fade-right');
      card2.setAttribute('data-aos', 'fade-left');
      card2.setAttribute('data-aos-delay', '0');
      card2.setAttribute('data-aos-delay', '0');
      card3.setAttribute('data-aos', 'fade-right');
    } else {
      card1.setAttribute('data-aos', '');
      card2.setAttribute('data-aos', '');
      card3.setAttribute('data-aos', '');

      card1Image.setAttribute('data-aos', 'fade-left');
      card1Text.setAttribute('data-aos', 'fade-right');

      card2Image.setAttribute('data-aos', 'fade-left');
      card2Text.setAttribute('data-aos', 'fade-right');

      card3Image.setAttribute('data-aos', 'fade-left');
      card3Text.setAttribute('data-aos', 'fade-right');
    }

    setTimeout(() => {
      AOS.init({
        offset: 100,
        duration: 850,
      });
    }, 150);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setActivePage('partnerschaft');
    initialAttributes();
    window.addEventListener('resize', switchAttributes);

    return () => {
      window.removeEventListener('resize', switchAttributes);
    };
  }, []);

  return (
    <>
      <Header activePage={props.activePage} setActivePage={props.setActivePage} />
      <main className='h-auto site--content--sm'>
        <div className='w-full page--main--image--holder overflow-hidden'>
          <img
            loading='lazy'
            src={Profiles}
            className='object-cover partnershaft--main--image'
          ></img>
        </div>
        <div className='w-[90%] mx-auto mb-40 flex partner--info'>
          <div className='text-center mt-20'>
            <div data-aos='fade-down' data-aos-offset='-100'>
              <p className='text-logo-orange font-semibold page--title text-3xl px-4'>
                Finelog ist Mitglied des gro{'\u00DF'}en und leistungsstarken Transportnetzwerkes
                E.L.V.I.S. in Deutschland.
              </p>
              <div className='w-full flex justify-center mt-10'>
                <a href='https://www.elvis-ag.com/' target='_blank'>
                  <img loading='lazy' src={ElvisLogo} data-aos='zoom-out'></img>
                </a>
              </div>
              <div
                className='flex flex-col w-[80%] mx-auto text-lg gap-2 partner--info text-logo-gray mt-10 site--content--text'
                data-aos='fade-down'
              >
                <p>ELVIS - Europ{'\u00E4'}ischer Ladungs- Verbund Internationeler Spediteure</p>
                <p>
                  Europas gr{'\u00F6'}
                  {'\u00DF'}tes Transportnetzwerk
                </p>
                <p>
                  ELVIS verbindet Partner, gibt denen die Chance sich kennen zu lernen, sich
                  gemeinsam zu entwickeln und schl{'\u00E4'}gt neue L{'\u00F6'}sungen vor, von denen
                  Alle profitiren k{'\u00F6'}nnen.
                </p>
                <p>Aktuell als Mitglieder haben ihren Platz bei ELVIS 231 Partner gefunden.</p>
                <p>Ca. 17 050 LKWs und 21 304 Auflieger sind unterwegs auf den Strassen Europas</p>
              </div>
            </div>
            <div className='partners--cards--holder mt-24'>
              <section className='partner--card' id='card1'>
                <div className='w-full h-auto image--partner--holder' id='card1image'>
                  <img loading='lazy' src={Highway} className='object-cover partner--image'></img>
                </div>
                <div className='partner--card--text' id='card1text'>
                  <p>
                    Dank der Partnerschaft mit Elvis befindet sich der Haupt Umschlagplatz im
                    Mittelpunkt Deutschlands, in Kn{'\u00FC'}llwald.
                  </p>
                  <p>
                    Das ist ein perferter und professioneller Punkt, um die Ladungen proffessionell
                    umzuschlagen.
                  </p>
                </div>
              </section>
              <section className='partner--card' id='card2'>
                <div className='w-full h-auto image--partner--holder' id='card2image'>
                  <img loading='lazy' src={Parking} className='object-cover partner--image'></img>
                </div>
                <div className='partner--card--text' id='card2text'>
                  <p>
                    In Kn{'\u00FC'}llwald treffen sich alle Sendungen aus ganz Europa und werden
                    weiter vertrieben.
                  </p>
                  <p>
                    Da treffen sich auch die meisten Frachtf{'\u00FC'}hrer Deutschlands und Finelog
                    nat
                    {'\u00FC'}
                    rlich auch.
                  </p>
                </div>
              </section>
              <section className='partner--card' id='card3'>
                <div className='w-full h-auto image--partner--holder' id='card3image'>
                  <img
                    loading='lazy'
                    src={HighwayBlur}
                    className='object-cover partner--image'
                  ></img>
                </div>
                <div className='partner--card--text' id='card3text'>
                  <p>
                    In Kn{'\u00FC'}llwald kreuzen sich Firmen, Ladungen, Fahrern, Gebelstapler und
                    Spedituere.
                  </p>
                  <p>Das ist ein Mittelpunkt der Logistikwelt in Deutschland und Europa.</p>
                </div>
              </section>
            </div>
            <div className='bg-logo-orange h-1 w-full rounded-full my-16'></div>
            <div
              className='w-[90%] mx-auto text-logo-gray text-lg flex flex-col gap-3 site--content--text'
              data-aos='fade-down'
            >
              <p>
                Die Partnerschaft bei ELVIS gibt Finelog die M{'\u00F6'}glichkeit st{'\u00E4'}ndig
                neue L{'\u00F6'}
                sungen und Praktiken in der Branche zu nutzen und ins Leben zu rufen.
              </p>{' '}
              <p>
                Finelog als Mitglied des ELVIS Verbundes nimmt an der Synergie der gr{'\u00F6'}
                {'\u00DF'}ten in Deutschland unabh{'\u00E4'}ngigen Frachtf{'\u00FC'}hrern und
                Speditionsunternehmen teil.
              </p>{' '}
              <p>
                Die Mitglieder k{'\u00F6'}nnen tagt{'\u00E4'}glich ihre Kosten und die operative T
                {'\u00E4'}
                tigkeit optimieren.
              </p>{' '}
              <p>
                Dank dessen bietet Finelog die h{'\u00F6'}hste Qualit{'\u00E4'}t und die gr
                {'\u00F6'}
                {'\u00DF'}te Sicherheit der Leistungen.
              </p>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
